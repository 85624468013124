<template>
  <div class="w-100 my-5">
    <span class="m-10 font-15 font-bold">Total: {{ paypalWebHookList.length }}</span>
    <div class="row my-5" v-if="(paypalWebHookList.length > 0)">
      <div class="col-sm-12" v-for="(item, index) in paypalWebHookList" :key="(item, index)">
        <v-card class="my-2 box-white d-flex flex-column">
          <div class="m-2 row">
            <span class="col-sm-3">{{ item.resource.id }}</span>
            <span class="col-sm-3">{{ item.event_type }}</span>
            <span class="col-sm-3">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
            <v-btn class="ml-auto" icon small @click="getPayPalOrderInfoTask(item)">
              <i class="fa fa-info-circle"></i>
            </v-btn>
            <v-btn class="mx-10" icon small @click="deletePayPalWebHookInfoTask(item.id)">
              <i class="flaticon-delete color-red"></i>
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getDateStringFromTimestamp, showLoading } from '../../../functions';

export default {
  name: 'PayPalWebHook',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    paypalWebHookList() {
      return this.$store.state.paypalWebHookList;
    }
  },
  mounted() {
    if (window.location.hostname !== 'localhost') {
      this.$router.push('/');
    }
  },
  methods: {
    getDateStringFromTimestamp,
    getPayPalOrderInfoTask(info) {
      const orderId = info.event_type === 'CHECKOUT.ORDER.APPROVED' ? info.resource.id : info.resource.supplementary_data.related_ids.order_id;
      if (!orderId) {
        this.$toast.error('No orderId?');
        return;
      }
      const params = {
        functionName: 'getPayPalOrder',
        orderId: orderId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        try {
          const info = JSON.parse(response.data);
          if (info) {
            const captureId = info.purchase_units && info.purchase_units.length > 0 && info.purchase_units[0] && info.purchase_units[0].payments && info.purchase_units[0].payments.captures && info.purchase_units[0].payments.captures.length > 0 ? info.purchase_units[0].payments.captures[0].id : 'No Capture Info';
            window.alert(`orderId = ${orderId}, captureId = ${captureId}`);
          }
        } catch (exception) {
          window.alert(response.data);
          if (confirm(`Delete? - ${orderId}`)) {
            this.deletePayPalWebHookInfoTask(info.id, false);
          }
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    deletePayPalWebHookInfoTask(id, showConfirm = true) {
      if (!id) {
        this.$toast.error('No id?');
        return;
      }
      if (showConfirm && !confirm(`Delete? - ${id}`)) {
        return;
      }
      const params = {
        functionName: 'deletePayPalWebhookInfo',
        id: id
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>